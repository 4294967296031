<template lang="pug">
    construction-block.w-auto.flex.flex-col
        div
            .flex.gap-3.mb-2
                span.block.cursor-pointer.text-sm.text-gray-400(
                    @click="onLastName"
                    :class="{'text-accent': isLastname}") {{ FORMS_LABELS.by_lastname }}
                span.block.cursor-pointer.text-sm.text-gray-400(
                    @click="onEmail"
                    :class="{'text-accent': !isLastname}") {{ FORMS_LABELS.by_email }}
            autocomplete(
                :search="onSearchUsers"
                :placeholder="isLastname ? FORMS_LABELS.enter_user_lastname : FORMS_LABELS.enter_user_email" 
                :get-result-value="getUserSearchResultValue"
                :debounce-time="1000"
                @submit="onSubmitUser"
                ref="autocomplete"
            )
            template(v-if="showUserCard")
                user-card.mt-4.mb-4(
                    :photo="user.photo"
                    :name="user.name"
                    :lastname="user.lastname"
                    :patronymic="user.patronymic"
                )
</template>

<script>
//Components
import ConstructionBlock from '@/components/common/block';
import TextInput from '@/components/common/forms/text-input';
import RegularButton from '@/components/common/buttons/regular-button';
import UserCard from '@/components/common/user/default-user-card';
//Config
import { BUTTONS_VALUES, FORMS_LABELS } from '@/configs/names.js';
//Store
import { UsersNameSpace, UsersActionTypes, UsersGetterTypes, UsersMutationTypes } from '@/store/users/types';
//Helpers
import { capitalizeFirstLetter } from '@/helpers/text-transform';

export default {
    name: "DefaultUserSearch",
    components: {
        'construction-block': ConstructionBlock,
        'text-input': TextInput,
        'regular-button': RegularButton,
        'user-card': UserCard,
    },
    props: {
        showUserCard: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            user: {},
            isLastname: true,
        }
    },
    computed: {
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        users() {
            return this.$store.getters[`${UsersNameSpace}/${UsersGetterTypes.GetUsers}`]
        },
    },
    methods: {
        capitalizeFirstLetter({ text }) {
            return capitalizeFirstLetter({ text })
        },
        async onLoadUsers({ input }) {
            this.user = {}
            const query_params = {}
            if (this.isLastname) query_params['lastname'] = input
            else query_params['email'] = input
            await this.$store.dispatch(`${UsersNameSpace}/${UsersActionTypes.GetUsers}`, { params: { ...query_params } })
        },
        async onSearchUsers(input) {
            await this.onLoadUsers({ input })
            return this.users
        },
        getUserSearchResultValue(result) {
            const firstname = capitalizeFirstLetter({ text: result.name })
            const lastname = capitalizeFirstLetter({ text: result.lastname })
            const patronymic = capitalizeFirstLetter({ text: result.patronymic })
            return `${firstname} ${patronymic ? patronymic : ''} ${lastname}`
        },
        onSubmitUser({ name = '', lastname = '', _id = '', photo = null, patronymic = ''} = {}) {
            this.user = {
                name, 
                lastname, 
                patronymic, 
                id: _id, 
                photo: photo && photo.path ? photo.path : null}
                
            const el = this.$refs['autocomplete']
            el.value = ''
        },
        onClearData() {
            this.user = {}
        },
        onLastName() {
            this.isLastname = true
            this.onClearData()
        },
        onEmail() {
            this.isLastname = false
            this.onClearData()
        }
    },
    beforeDestroy() {
        this.$store.commit(`${UsersNameSpace}/${UsersMutationTypes.SetEmptyUsers}`)
    },
    watch: {
        user: {
            handler(val) {
                this.$emit('value', val)
            },
            deep: true,
        }
    }
}
</script>