<template lang="pug">
    .container.relative.w-full.border.rounded.p-3.shadow.flex.items-center
        div
            template(v-if="photo")
                div.border.rounded-full.w-16.h-16.bg-cover(
                    :style="`background-image: url(${base_url}/${photo})`"
                )
            template(v-else)
                div.border.rounded-full.w-16.h-16.bg-gray-300
        .flex.gap-2.ml-4.font-light
            span.block {{ name }}
            template(v-if="patronymic")
                span.block {{ patronymic }}
            span.block {{ lastname }}
        template(v-if="hasRemoveButton")
            .absolute.right-2.top-2.cursor-pointer
                inline-svg.m-auto(
                    :src="ICONS.cross" 
                    @click="$emit('remove')"
                )
</template>

<script>
//Configs
import { ICONS } from '@/configs/icons';

export default {
    name: "DefaultUserCard",
    props: {
        name: {
            type: String,
        },
        lastname: {
            type: String,
        },
        patronymic: {
            type: String,
        },
        photo: {
            type: String,
        },
        hasRemoveButton: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        ICONS() {
            return ICONS
        },
        base_url() {
            return process.env.VUE_APP_BASE_URL
        },
    },
}
</script>