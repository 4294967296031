<template lang="pug">
    construction-block.w-500.flex.flex-col
        .flex.gap-3.mb-4
            span.block.cursor-pointer.text-sm.text-gray-400(
                @click="onSelectIsExistingUser"
                :class="{'text-accent': isExistingUser}") {{ FORMS_LABELS.user_exists }}
            span.block.cursor-pointer.text-sm.text-gray-400(
                @click="onSelectNewUser"
                :class="{'text-accent': isNewUser}") {{ FORMS_LABELS.add_new_user }}
        div
            template(v-if="isExistingUser")
                user-search(@value="onUser")
            template(v-if="isNewUser")
                add-user.mt-5.mb-5(@user="onNewUser")
            template(v-if="user.id")
                .container.w-full.border.rounded.p-3.shadow
                    v-select.mb-3(:options="companies" v-model="department" :placeholder="FORMS_LABELS.work_place")
                    text-input(:label="FORMS_LABELS.job_title" @value="job_title = $event")
            
            regular-button.mt-4(:value="BUTTONS_VALUES.save" :isActive="isSaveButtonActive" @click="onSave")
</template>

<script>
//Components
import DefaultUserSearch from '@/components/common/user/default-user-search';
import ConstructionBlock from '@/components/common/block';
import TextInput from '@/components/common/forms/text-input';
import RegularButton from '@/components/common/buttons/regular-button';
import AddUser from '@/components/views/users/add-user';
//Config
import { BUTTONS_VALUES, FORMS_LABELS } from '@/configs/names.js';
//Store
import { CompanyNameSpace, CompanyGetterTypes, CompanyActionTypes, CompanyMutationTypes } from '@/store/company/types';
import { EmployeeNameSpace, EmployeeActionTypes } from '@/store/employee/types';

export default {
    name: "AddEmployeeCard",
    components: {
        'construction-block': ConstructionBlock,
        'text-input': TextInput,
        'regular-button': RegularButton,
        'user-search': DefaultUserSearch,
        'add-user': AddUser,
    },
    data() {
        return {
            user: {},
            department: null,
            job_title: '',
            isExistingUser: false,
            isNewUser: true,
        }
    },
    computed: {
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        companies() {
            const result = this.$store.getters[`${CompanyNameSpace}/${CompanyGetterTypes.GetCompanies}`]
                .map(item => {
                    item.label = item.name
                    return item
                })
            return result
        },
        isSaveButtonActive() {
            return this.department ? true : false
        }
    },
    methods: {
        onUser(value) {
            this.user = value
            if (this.user.id)
                this.onGetCompany()
            else 
                this.onClearData()
        },
        onNewUser(value) {
            console.log(value)
            this.user = value
            if (this.user.id)
                this.onGetCompany()
            else 
                this.onClearData()
        },
        async onGetCompany() {
            await this.$store.dispatch(`${CompanyNameSpace}/${CompanyActionTypes.GetCompanies}`, {params: {}})
        },
        async onSave() {
            await this.$store.dispatch(`${EmployeeNameSpace}/${EmployeeActionTypes.AddEmployee}`, {
                user: this.user.id,
                department: [{
                    _id: this.department._id,
                    job_title: this.job_title,
                }]
            })
        },
        onClearData() {
            this.user = {}
            this.department = null
            this.job_title = ''
            this.$store.commit(`${CompanyNameSpace}/${CompanyMutationTypes.SetEmptyCompanies}`)
        },
        onSelectIsExistingUser() {
            this.isExistingUser = !this.isExistingUser
            this.isNewUser = !this.isNewUser
            this.user = {}
        },
        onSelectNewUser() {
            this.isNewUser = !this.isNewUser
            this.isExistingUser = !this.isExistingUser
            this.user = {}
        }
    },
    beforeDestroy() {
        this.$store.commit(`${CompanyNameSpace}/${CompanyMutationTypes.SetEmptyCompanies}`)
    },
}
</script>