<template lang="pug">
    div.p-3.border
        add-employee-card
</template>

<script>
//Components
import AddEmployeeCard from './components/add-employee-card.vue'; 

export default {
    name: "AddEmployeeComponent",
    components: {
        'add-employee-card': AddEmployeeCard,
    }
}
</script>