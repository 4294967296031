<template lang="pug">
    add-employee
</template>

<script>
import AddEmployee from '@/components/views/employees/add-employee';

export default {
    name: "AddEmployeePage",
    components: {
        'add-employee': AddEmployee,
    },
}
</script>